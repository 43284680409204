import styled from "styled-components";
import {
  BP,
  Colors,
  Rem,
  Ratio,
} from "../../commons/Theme";
import { StyledButton } from "../Button/style";

export const StyledUsersMap = styled.div`
  position: relative;
  color: ${Colors.black};
  background-color: ${Colors.white};
  margin-bottom: ${Rem(60)};

  @media (${BP.ipad}) {
    margin-bottom: ${Rem(100)};
  }
`;

export const StyledUsersMapContent = styled.div`
  padding-top: ${Rem(62)};
  padding-bottom: ${Rem(36)};

  @media (${BP.ipad}) {
    position: absolute;
    width: 50%;
    padding-top: ${Rem(154)};
    padding-right: ${Rem(120)};
  }
`;

export const StyledUsersMapContentCTA = styled.div`
  display: inline-block;

  ${StyledButton} {
    color: ${Colors.black};
  }

  @media (${BP.ipad}) {
  }
`;

export const StyledUsersMapImage = styled.div`
  position: relative;
  ${Ratio(375, 300)};
  width: 100%;
  background-image: url('${(props) => props.bg}');
  background-position: center;
  background-size: cover;
  
  @media (${BP.ipad}) {
    ${Ratio(708, 924)};
    width: 50%;
    height: ${Rem(924)};
    left: 50%;
  }
`;
